import { defineStore } from 'pinia';

export const useStatsStore = defineStore('Stats', {
  state: () => ({
    overUnderStats: {},
    firstGoalInMatchStats: {},
    matchesPenaltiesStats: {},
    matchesCardsStats: {},
    detailedStats: {},
    halvesStats: {},
    cornersStats: {},
    quarterStats: {},
    eventStats: {},
    refereeStats: {},
    ballPossessionLastMatchesStats: {},
    offsidesLastMatchesStats: {},
    shotsOnGoalLastMatchesStats: {},
    foulsLastMatchesStats: {},
    requestFlags: {
      'api_get_over_under_stats': {},
      'api_get_first_goal_in_match_stats': {},
      'api_get_matches_penalties_stats': {},
      'api_get_matches_cards_stats': {},
      'api_get_detailed_statistics_for_league': {},
      'api_get_halves_stats': {},
      'api_get_matches_corners_stats': {},
      'api_get_quarters_stats': {},
      'api_get_football_events_stats': {},
      'api_get_referee_stats': {},
      'api_get_ball_possession_last_matches': {},
      'api_get_offsides_last_matches': {},
      'api_get_shotsongoal_last_matches': {},
      'api_get_fouls_last_matches': {}
    }
  }),
  getters: {
  },
  actions: {
    getOverUnderStats(leagueId = -1) {
      if (this.overUnderStats[leagueId] === undefined
        && this.requestFlags['api_get_over_under_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_over_under_stats'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_over_under_stats',
          league_id: leagueId,
        }).then(response => {
          if (response.data.success) {
            this.overUnderStats[leagueId] = Array.from(response.data.data);
          }
        });
      }
    },
    getFirstGoalInMatchStats(leagueId = -1, noOfMatches = -1) {
      let oldLeagueId = leagueId;
      if (noOfMatches > 0) {
        leagueId = `${leagueId}-${noOfMatches}`;
      }
      if (this.firstGoalInMatchStats[leagueId] === undefined
        && this.requestFlags['api_get_first_goal_in_match_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_first_goal_in_match_stats'][oldLeagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_first_goal_in_match_stats',
          league_id: oldLeagueId,
          no_of_matches: noOfMatches
        }).then(response => {
          if (response.data.success) {
            this.firstGoalInMatchStats[leagueId] = response.data.data;
          }
        });
      }
    },
    getMatchesPenaltiesStats(leagueId = -1, noOfMatches = -1) {
      let oldLeagueId = leagueId;
      if (noOfMatches > 0) {
        leagueId = `${leagueId}-${noOfMatches}`;
      }
      if (this.matchesPenaltiesStats[leagueId] === undefined
        && this.requestFlags['api_get_matches_penalties_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_matches_penalties_stats'][oldLeagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_matches_penalties_stats',
          league_id: oldLeagueId,
          no_of_matches: noOfMatches
        }).then(response => {
          this.matchesPenaltiesStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getMatchesCardsStats(leagueId = -1) {
      if (this.matchesCardsStats[leagueId] === undefined
        && this.requestFlags['api_get_matches_cards_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_matches_cards_stats'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_matches_cards_stats',
          league_id: leagueId,
        }).then(response => {
          let teams = Array.from(response.data.data);
          this.matchesCardsStats[leagueId] = teams.filter((team) => { return team.matches.length >= 5 });
        });
      }
    },
    getDetailedStatisticsForLeague(leagueId = -1, noOfMatches = -1) {
      let oldLeagueId = leagueId;
      if (noOfMatches > 0) {
        leagueId = `${leagueId}-${noOfMatches}`;
      }
      if (this.detailedStats[leagueId] === undefined
        && this.requestFlags['api_get_detailed_statistics_for_league'][leagueId] === undefined) {
        this.requestFlags['api_get_detailed_statistics_for_league'][oldLeagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_detailed_statistics_for_league',
          league_id: oldLeagueId,
          no_of_matches: noOfMatches
        }).then(response => {
          this.detailedStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getHalvesStats(leagueId = -1) {
      if (this.halvesStats[leagueId] === undefined
        && this.requestFlags['api_get_halves_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_halves_stats'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_halves_stats',
          league_id: leagueId,
        }).then(response => {
          this.halvesStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getMatchesCornerStats(leagueId = -1) {
      if (this.cornersStats[leagueId] === undefined
        && this.requestFlags['api_get_matches_corners_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_matches_corners_stats'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_matches_corners_stats',
          league_id: leagueId,
        }).then(response => {
          this.cornersStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getQuartersStats(leagueId = -1, noOfMatches = -1) {
      let oldLeagueId = leagueId;
      if (noOfMatches > 0) {
        leagueId = `${leagueId}-${noOfMatches}`;
      }
      if (this.quarterStats[leagueId] === undefined
        && this.requestFlags['api_get_quarters_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_quarters_stats'][oldLeagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_quarters_stats',
          no_of_matches: noOfMatches,
        }).then(response => {
          this.quarterStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getEventStats(leagueId = -1) {
      if (this.eventStats[leagueId] === undefined
        && this.requestFlags['api_get_football_events_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_football_events_stats'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_football_events_stats',
          league_id: leagueId,
        }).then(response => {
          this.eventStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getRefereeStats(leagueId = -1) {
      if (this.refereeStats[leagueId] === undefined
        && this.requestFlags['api_get_referee_stats'][leagueId] === undefined) {
        this.requestFlags['api_get_referee_stats'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_referee_stats',
          league_id: leagueId,
        }).then(response => {
          this.refereeStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getBallPossessionLastMatchesStats(leagueId = -1) {
      if (this.ballPossessionLastMatchesStats[leagueId] === undefined
        && this.requestFlags['api_get_ball_possession_last_matches'][leagueId] === undefined) {
        this.requestFlags['api_get_ball_possession_last_matches'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_ball_possession_last_matches',
          league_id: leagueId,
        }).then(response => {
          this.ballPossessionLastMatchesStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getOffsidesLastMatchesStats(leagueId = -1) {
      if (this.offsidesLastMatchesStats[leagueId] === undefined
        && this.requestFlags['api_get_offsides_last_matches'][leagueId] === undefined) {
        this.requestFlags['api_get_offsides_last_matches'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_offsides_last_matches',
          league_id: leagueId,
        }).then(response => {
          this.offsidesLastMatchesStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getShootsOnGoalLastMatchesStats(leagueId = -1) {
      if (this.shotsOnGoalLastMatchesStats[leagueId] === undefined
        && this.requestFlags['api_get_shotsongoal_last_matches'][leagueId] === undefined) {
        this.requestFlags['api_get_shotsongoal_last_matches'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_shotsongoal_last_matches',
          league_id: leagueId,
        }).then(response => {
          this.shotsOnGoalLastMatchesStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
    getFoulsLastMatchesStats(leagueId = -1) {
      if (this.foulsLastMatchesStats[leagueId] === undefined
        && this.requestFlags['api_get_fouls_last_matches'][leagueId] === undefined) {
        this.requestFlags['api_get_fouls_last_matches'][leagueId] = 1;
        axios.post(window.WP.ajaxUrl, {
          endpoint: 'api_get_fouls_last_matches',
          league_id: leagueId,
        }).then(response => {
          this.foulsLastMatchesStats[leagueId] = Array.from(response.data.data);
        });
      }
    },
  }
})